<template>
  <div
    class="dropdown-menu p-2"
    style="min-width:100px; right:0; left:auto"
    aria-labelledby="triggerId"
  >
    <div v-for="item in cart" :key="item.index">
      <div class="px-2 d-flex justify-content-between">
        <div>
          <strong>{{ item.productName }}</strong>
          <br />
          {{ item.quantity }} x ${{ item.price }}
        </div>
        <div>
        </div>
      </div>
      <hr>
    </div>
  </div>
</template>

<script>
 export default {
    computed: {
        cart() {
            return this.$store.state.cart;
        }
    },
  mounted() {
  },
  methods: {
  }
};
</script>

<style>
</style>